import { Box, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import peopleLogo from '../img/category/people.svg';
import proactivityLogo from '../img/category/proactivity.svg';
import purposeLogo from '../img/category/purpose.svg';
import processLogo from '../img/category/process.svg';
import brcgsPeopleLogo from '../img/category/brcgs-people.png';
import brcgsProactivityLogo from '../img/category/brcgs-proactivity.png';
import brcgsPurposeLogo from '../img/category/brcgs-purpose.png';
import brcgsProcessLogo from '../img/category/brcgs-process.png';
import CategoryLabel from "../../Translation/CategoryLabel";
import CompareArrow from "./Common/CompareArrow";
import DimensionLabel from "../../Translation/DimensionLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";


export default function BenchmarkRegion(props) {
    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    // Determine colors and logos for report based on type
    const categoryColors = props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;
    const categoryLogos = props.surveyData.attributes.type === 'brcgs' ? {
        people: brcgsPeopleLogo,
        proactivity: brcgsProactivityLogo,
        purpose: brcgsPurposeLogo,
        process: brcgsProcessLogo,
    } : {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    const scoreChange = (current, region, precision = 0) => {
        if (!current || !region) {
            return '-';
        }
        return (Decimal(current).minus(Decimal(region))).toFixed(precision);
    };

    return (
        <div ref={props.setScreenshotRef}>
            {Object.keys(props.reportData.data.current.categories).map((categoryKey, categoryIndex) => {
                const categoryChange = scoreChange(props.reportData.data.current.categories[categoryKey].roundedScore, props.reportData.data.region ? props.reportData.data.region.categories[categoryKey].roundedScore : null)
                return (
                    <Box pb={2} pt={(categoryIndex === 0 ? 2 : 0)} key={categoryKey}>
                        <Grid container spacing={2}>
                            {/* Categories */}
                            <Grid item sm={6} xs={12} align="center">
                                {0 === categoryIndex ?
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item sm={3} align="center"></Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.score" defaultMessage={"Score"} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.region" defaultMessage={"Region"} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.comparison" defaultMessage={"Comparison"} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                : null}
                                <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+categoryColors[categoryKey].dark, height: 200, backgroundColor: categoryColors[categoryKey].light}}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center" style={{height: '100%'}}>
                                        <Grid item sm={3} align="center">
                                            <img style={{ maxWidth: '110px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} />
                                            <Typography variant="subtitle1">
                                                <CategoryLabel category={categoryKey} upperCase={true} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            {/* Current */}
                                            <Typography title={props.reportData.data.current.categories[categoryKey].score ? props.reportData.data.current.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.current.categories[categoryKey].roundedScore ? props.reportData.data.current.categories[categoryKey].roundedScore : '-'}</Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            {/* Region */}
                                            {props.reportData.data.region && props.reportData.data.region.categories[categoryKey] && props.reportData.data.region.categories[categoryKey].roundedScore ?
                                                <Tooltip title={
                                                    <span>
                                                        Avg: <strong>{props.reportData.data.region.categories[categoryKey].score || '-'}</strong><br />
                                                        Max: <strong>{props.reportData.data.region.categories[categoryKey].max || '-'}</strong><br />
                                                        Min: <strong>{props.reportData.data.region.categories[categoryKey].min || '-'}</strong>
                                                    </span>}
                                                >
                                                    <Typography title={props.reportData.data.region.categories[categoryKey].score ? props.reportData.data.region.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.region.categories[categoryKey].roundedScore ? props.reportData.data.region.categories[categoryKey].roundedScore : '-'}</Typography>
                                                </Tooltip>
                                            : <Typography variant="h2">-</Typography>}
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            {/* Change */}
                                            {'-' !== categoryChange ?
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography variant="h2" style={{fontWeight: 'normal'}}>
                                                        {categoryChange}
                                                    </Typography>
                                                    <CompareArrow change={categoryChange} large={true} />
                                                </div>
                                            : <Typography variant="h2">-</Typography>}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item sm={6} xs={12} align="center">
                                {0 === categoryIndex ?
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item sm={3} align="center"></Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.score" defaultMessage={"Score"} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.region" defaultMessage={"Region"} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} align="center">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="generic.comparison" defaultMessage={"Comparison"} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                : null}
                                {/* Dimensions */}
                                <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+categoryColors[categoryKey].dark, height: 200, backgroundColor: categoryColors[categoryKey].light}}>
                                    {Object.keys(props.reportData.data.current.categories[categoryKey].dimensions).map((dimensionKey, dimensionIndex) => {
                                        const dimensionChange = scoreChange(props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore, props.reportData.data.region ? props.reportData.data.region.dimensions[dimensionKey].roundedScore : null)
                                        return (
                                            <Grid container direction="row" justifyContent="center" alignItems="center" key={dimensionIndex} style={{height: '20%'}}>
                                                <Grid item sm={3} align="left">
                                                    <Box pl={2}>
                                                        <DimensionLabel dimension={dimensionKey} />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3} align="center">
                                                    <Typography variant="subtitle1">
                                                        {props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={3} align="center">
                                                    <Tooltip title={
                                                        props.reportData.data.region ?
                                                        <span>
                                                            Avg: <strong>{props.reportData.data.region.dimensions[dimensionKey].score || '-'}</strong><br />
                                                            Max: <strong>{props.reportData.data.region.dimensions[dimensionKey].max || '-'}</strong><br />
                                                            Min: <strong>{props.reportData.data.region.dimensions[dimensionKey].min || '-'}</strong>
                                                        </span>
                                                        : ''
                                                        }
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {props.reportData.data.region && props.reportData.data.region.dimensions[dimensionKey].roundedScore ? props.reportData.data.region.dimensions[dimensionKey].roundedScore : '-'}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={3} align="center">
                                                    {'-' !== dimensionChange ?
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <Typography variant="subtitle1" style={{fontWeight: 'normal'}}>
                                                                {dimensionChange}
                                                            </Typography>
                                                            <CompareArrow change={dimensionChange} />
                                                        </div>
                                                    : '-'}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </div>
    );
}